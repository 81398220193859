import { Fragment, useEffect, useRef, useState } from "react";
import Header from "../component/layout/headertwo";
import AboutTwo from "../component/section/abouttwo";
import Pagination from "./member/pagination";
import { Link, useSearchParams } from "react-router-dom";
import SelectOrder from "./member/selectOrder";
import { getApiListingUsers, getEmptyListUsers, getHeadersApiListingUsers } from "../service/User";
import Footer from "../component/layout/mobilefooter";
import { useTranslation } from "react-i18next";
import UserData from "./user/search/userData";
import AppSectionTwo from "../component/section/appsectiontwo";
import { useWindowSize } from "../service/Window";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";
import TevifSpinner from "../component/spinner/tevifSpinner";
import { getProfileName } from "../service/String";
import MediaTevif from "../component/MediaTevif/MediaTevif";

const {REACT_APP_API_URL} = process.env;

function Members() {
    let apiUrl = getApiListingUsers();
    let apiHeaders = getHeadersApiListingUsers();

    const resultSearchRef = useRef(); 
    
    const emptyCollection = getEmptyListUsers();
    
    const [queryParameters] = useSearchParams();
    
    const [gender,setGender] = useState(queryParameters.get("gender")?? "");
    const [ageFrom,setAgeFrom] = useState(queryParameters.get("age_from") ?? "18");
    const [ageTo,setAgeTo] = useState(queryParameters.get("age_to") ?? "60");
    const [country,setCountry] = useState(queryParameters.get("country") ?? "");
    const [city,setCity] = useState(queryParameters.get("city") ?? "");
    const [cityName,setCityName] = useState(queryParameters.get("cityName") ?? "");
    
    const [page,setPage] = useState(queryParameters.get("page") ?? "1");
    const [orderField,setOrderField] = useState(queryParameters.get("order_field") ?? "lastActivate");
    
    const [spinner, setSpinner] = useState(0);    
    const [usersCollection, setUsersCollection] = useState(emptyCollection);
    const [totalItems, setTotalItems] = useState(0)

    
    //languages
    const [languageArabic, setLanguageArabic] = useState(queryParameters.get("languages[1]") ? true : false);
    const [languageBengali, setLanguageBengali] = useState(queryParameters.get("languages[2]") ? true : false);
    const [languageChinese, setLanguageChinese] = useState(queryParameters.get("languages[3]") ? true : false);
    const [languageEnglish, setLanguageEnglish] = useState(queryParameters.get("languages[4]") ? true : false);
    const [languageFrench, setLanguageFrench] = useState(queryParameters.get("languages[5]") ? true : false);
    const [languageHindi, setLanguageHindi] = useState(queryParameters.get("languages[6]") ? true : false);
    const [languagePortuguese, setLanguagePortuguese] = useState(queryParameters.get("languages[7]") ? true : false);
    const [languageRussian, setLanguageRussian] = useState(queryParameters.get("languages[8]") ? true : false);
    const [languageSpanish, setLanguageSpanish] = useState(queryParameters.get("languages[9]") ? true : false);
    const [languageUrdu, setLanguageUrdu] = useState(queryParameters.get("languages[10]") ? true : false);
    const [languageTurkic, setLanguageTurkic] = useState(queryParameters.get("languages[11]") ? true : false);

    const updateLanguages = (id) => {
        if(id === 1) {setLanguageArabic(!languageArabic);}
        if(id === 2) {setLanguageBengali(!languageBengali);}
        if(id === 3) {setLanguageChinese(!languageChinese);}
        if(id === 4) {setLanguageEnglish(!languageEnglish);}
        if(id === 5) {setLanguageFrench(!languageFrench);}
        if(id === 6) {setLanguageHindi(!languageHindi);}
        if(id === 7) {setLanguagePortuguese(!languagePortuguese);}
        if(id === 8) {setLanguageRussian(!languageRussian);}
        if(id === 9) {setLanguageSpanish(!languageSpanish);}
        if(id === 10) {setLanguageUrdu(!languageUrdu);}
        if(id === 11) {setLanguageTurkic(!languageTurkic);}
    }
    // end languages

    let languages = '';
        if(languageArabic) {languages = languages + '&languages[1]=1';}
        if(languageBengali) {languages = languages + '&languages[2]=2';}
        if(languageChinese) {languages = languages + '&languages[3]=3';}
        if(languageEnglish) {languages = languages + '&languages[4]=4';}
        if(languageFrench) {languages = languages + '&languages[5]=5';}
        if(languageHindi) {languages = languages + '&languages[6]=6';}
        if(languagePortuguese) {languages = languages + '&languages[7]=7';}
        if(languageRussian) {languages = languages + '&languages[8]=8';}
        if(languageSpanish) {languages = languages + '&languages[9]=9';}
        if(languageUrdu) {languages = languages + '&languages[10]=10';}
        if(languageTurkic) {languages = languages + '&languages[11]=11';}

    useEffect(() => {
        resultSearchRef.current?.scrollIntoView({behavior: "smooth", block: "start", inline: "start"});
    }, [spinner]);

    useEffect(() => {
        async function fetchUsers() {        
            try {
                setSpinner(1);
                const response = await fetch(
                    `${REACT_APP_API_URL}${apiUrl}?gender=${gender}&age_from=${ageFrom}&age_to=${ageTo}&country=${country}&city=${city}&order_field=${orderField}&page=${page}${languages}`, 
                    {
                        method: "GET",
                        headers: apiHeaders
                    }
                )
                const data = await response.json();
    
                setSpinner(0);
                setUsersCollection(data);
                setTotalItems(data.totalItems);
                if(data.parameters.city) {
                    let cityApi = data.parameters.city;
                    setCountry(cityApi.country.id);
                    setCityName(cityApi.name);
                }
            } catch (err) {} 
        }
        fetchUsers();
    
    }, []);

    const {t} = useTranslation();
    const [width, height] = useWindowSize();

    let sectionStyle = {};
    if(width < 576) {
        sectionStyle = {marginTop: "20px"};
    }

    return (
        <Fragment>
            <Header />
            <AboutTwo 
                gender={gender}
                ageFrom={ageFrom}
                ageTo={ageTo}
                country={country}
                emptyCollection={emptyCollection}                
                city={city}
                cityName={cityName}
                setGender={setGender}
                setAgeFrom={setAgeFrom}
                setAgeTo={setAgeTo}
                setCountry={setCountry}
                setUsersCollection={setUsersCollection}
                setTotalItems={setTotalItems}
                setSpinner={setSpinner}
                orderField={orderField}
                isMemberPage={true}
                apiUrl={apiUrl}
                apiHeaders={apiHeaders}
                setCity={setCity}
                searchTypeForm={"classic"}
                languageArabic={languageArabic}
                languageBengali={languageBengali}
                languageChinese={languageChinese}
                languageEnglish={languageEnglish}
                languageFrench={languageFrench}
                languageHindi={languageHindi}
                languagePortuguese={languagePortuguese}
                languageRussian={languageRussian}
                languageSpanish={languageSpanish}
                languageUrdu={languageUrdu}
                languageTurkic={languageTurkic}
                updateLanguages={updateLanguages}
                resultSearchRef={resultSearchRef}
            />
            <InitializeAndResumeBanner />
            <section className="member-page-section padding-tb  banner-admob-margin">
                <div className="container"  style={sectionStyle}>
                    <div className="member-wrapper">
                        <ul className="member-info mb-4">
                            <li className="all-member">
                                <p>{t('members')}</p>
                                <p>{totalItems}</p>
                            </li>
                            <li className="member-cat">
                                <div className="custom-select right w-100">
                                    <SelectOrder 
                                        gender={gender}
                                        ageFrom={ageFrom}
                                        ageTo={ageTo}
                                        country={country}
                                        city={city}
                                        orderField={orderField}
                                        setOrderField={setOrderField}
                                        setUsersCollection={setUsersCollection}
                                        emptyCollection={emptyCollection}
                                        setSpinner={setSpinner}
                                        apiUrl={apiUrl}
                                        apiHeaders={apiHeaders}
                                        cityName={cityName}
                                    />
                                </div>
                            </li>
                        </ul>       
                        <MediaTevif pageName={'members'} />                 
                        { spinner ? (
                            <div className="row g-3 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">
                                <ul className="react-list">
                                    <li className="react">
                                        <TevifSpinner/>
                                    </li>
                                </ul> 
                            </div>
                            ) : (
                                <div className="row g-3 row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">
                                    {usersCollection.items.map((val, i) => (
                                        <Link to={`/profile/${val.username}`} key={`user_profile_${val.id}`}>
                                            <div className="col" key={i}>
                                                <div className="lab-item member-item style-1">
                                                    <div className="lab-inner">
                                                        <div className="lab-thumb">
                                                            <img 
                                                                src={val.publicPathCropedProfilePicture} 
                                                                alt={val.username} 
                                                            />
                                                        </div>
                                                        <div className="lab-content">
                                                            <h6>
                                                                <i className="active green" style={{background: val.colorActive}}></i>
                                                                &nbsp;{getProfileName(val.username, val.firstName ?? '', val.lastName ?? '', true)}
                                                                {val.verified ? (<i className="icofont-check" title="Verify Profile"></i>) : null }
                                                            </h6>                                                            
                                                            <UserData 
                                                                user={val}
                                                            />

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            )
                        }
                        { spinner != 1 ? (<Pagination 
                            totalPages={usersCollection.totalPages}
                            currentPage={usersCollection.pagination.page}
                            setUsersCollection={setUsersCollection}
                            gender={gender}
                            ageFrom={ageFrom}
                            ageTo={ageTo}
                            country={country}
                            city={city}
                            orderField={orderField}
                            emptyCollection={emptyCollection}
                            setSpinner={setSpinner}
                            apiUrl={apiUrl}
                            apiHeaders={apiHeaders}
                            cityName={cityName}
                            languages={languages}
                        />) : null}
                    </div>
                </div>
            </section>
            <AppSectionTwo />
            <Footer />
        </Fragment>
    );
}
 
export default Members;