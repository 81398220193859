import { Fragment, useState } from "react";
import Header from "../component/layout/headertwo";
import { useWindowSize } from "../service/Window";
import { useTranslation } from "react-i18next";
import Footer from "../component/layout/mobilefooter";
import AppSectionTwo from "../component/section/appsectiontwo";
import InitializeAndResumeBanner from "../component/adMob/initializeBanner";

const {REACT_APP_API_URL} = process.env;

function SecurityPage () {

    const [conName, setConName] = useState("");
    const [conEmail, setConEmail] = useState("");
    const [conSubject, setConSubject] = useState("");
    const [conPhone, setConPhone] = useState("");
    const [conMessage, setConMessage] = useState("");
    const [conDesc, setConDesc] = useState("");
    const [colorConDesc, setColorConDesc] = useState("white");
    const [width, height] = useWindowSize();

    const handleSubmit = async e => {
        e.preventDefault();
        
        const bodyRequest = {
            'name': conName,
            'email': conEmail,
            'subject': conSubject,
            'phone': conPhone,
            'message': conMessage,
        };

        if(!conEmail || !conMessage) {
               setConDesc(t("email_and_message_fields_cannot_be_empty"));
               setColorConDesc("#f24570");  
        } else {
            fetch(`${REACT_APP_API_URL}/api/contact-us`, {
                method: "POST",
                body: JSON.stringify(bodyRequest),
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
              }).then(
              (response) => (response.json())
                ).then((response)=> {
              if (response.email === conEmail) {
                setConDesc(t("thank_you_your_message_has_been_sent_successfully"));  
                setColorConDesc("green");  
                resetForm()
              } else if(response.code === 400) {
                setConDesc(t("please_enter_a_valid_email_address"));  
                setColorConDesc("#f24570");  
              } else {
                setConDesc(t("an_error_has_occurred_please_retry_later"));  
                setColorConDesc("red");   
              }
            })
        }
    }

    const resetForm = () => {
        setConName("");
        setConEmail("");
        setConSubject("");
        setConPhone("");
        setConMessage("");
    }

    const {t} = useTranslation();
    return (
        <Fragment>
        <Header />
        <InitializeAndResumeBanner />
        <section className="terms-section padding-tb banner-admob-margin" style={process.env.REACT_APP_IS_IOS_APP === "true" ? {marginTop: "35px"} : {}}>
            <div className="container">
                <div className="terms-content">
                    <div className="terms-header">
                        <h4>{t('security_title')}</h4>
                        <p><span className="theme-color fw-bold">{t('last_update')}:</span> 2025-03-27</p>
                    </div>
                    <div className="terms-text">
                        <ol>
                            <li>
                                <h5>{t('security_h1')}</h5>
                                <p>{t('security_p1')}</p>
                            </li>
                            <li>
                                <h5>{t('security_h2')}</h5>
                                <p>{t('security_p2')}</p>
                            </li>
                            <li>
                                <h5>{t('security_h3')}</h5>
                                <p>{t('security_p3')}</p>
                            </li>
                            <li>
                                <h5>{t('security_h4')}</h5>
                                <p>{t('security_p4')}</p>
                            </li>
                            <li>
                                <h5>{t('security_h5')}</h5>
                                <p>{t('security_p5')}</p>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </section>
        <AppSectionTwo />
        <Footer />
    </Fragment>
    );
}
 
export default SecurityPage;