import { useEffect, useState } from "react";
import {BrowserRouter, Routes, Route } from "react-router-dom";
import 'swiper/css';
import PrivateRoutes from "./util/PrivateRoutes";

import ScrollToTop from "./component/layout/ScrollToTop";
import Auth from "./context/Auth";
import LightMode from "./context/LightMode";
import ErrorPage from "./page/404";
import ContactPage from "./page/contact";
import ForgetPassword from "./page/forgetpassword";
import NewsActivity from "./page/news-activity";
import HomeTwo from "./page/hometwo";
import LogIn from "./page/login";
import Members from "./page/members";
import Policy from "./page/policy";
import ProfileUser from "./page/user/profileUser";
import ProfileCurrentUser from "./page/user/profileCurrentUser";
import Conversations from "./page/conversations";
import SignUp from "./page/signup";
import { hasAuthenticated } from "./service/AuthApi";
import ShowConveration from "./page/conversation/showConversation";
import MembersInMaps from "./page/membersInMaps";
import ResetPassword from "./page/resetPassword";
import DeletedProfile from "./page/deletedProfile";
import { useTranslation } from "react-i18next";
import { getItem } from "./service/LocalStorage";
import { getLangFromUrl } from "./service/Language";
import { App as CapacitorApp } from '@capacitor/app';
import { isLightModeActived } from "./service/ModeApi";
import ThemeReels from "./page/themeReels";
import { initializeAdMob, showBanner } from "./component/adMob/adMob";
import ValidateProfile from "./page/validateProfile";
import ShowActivity from "./page/showActivity";
import PushNotificationsComponent from "./component/notification/PushNotificationsComponent";
import ConversationChatRequest from "./page/conversationChatRequest";
import SecurityPage from "./page/security";


function App() {
	const [isAuthenticated, setIsAuthenticated] = useState(hasAuthenticated());
	const [isLightMode, setIsLightMode] = useState(isLightModeActived());
	const {t} = useTranslation();
	const currentLanguage = getItem('i18nextLng') ?? getLangFromUrl();

	CapacitorApp.addListener('backButton', ({canGoBack}) => {
		if(!canGoBack){
		  CapacitorApp.exitApp();
		} else {
		  window.history.back();
		}
	});

	useEffect(() => {    		
		const root = document.documentElement;
		root.style.setProperty('--dark-blue-color', isLightMode ? '#dbdbdb' : '#222222');
		root.style.setProperty('--midnight-blue-color', isLightMode ? '#fff' : '#000');
		root.style.setProperty('--midnight-blue-color-2', isLightMode ? '#b9b9b9' : '#505050');
		root.style.setProperty('--midnight-blue-color-3', isLightMode ? '#a8a8a8' : '#1a3e86');
		root.style.setProperty('--midnight-blue-color-4', isLightMode ? '#979797' : '#808080');
		root.style.setProperty('--cap-txt-color', isLightMode ? '#000000' : '#fff');
		root.style.setProperty('--body-txt-color', isLightMode ? '#000000' : '#fff');
		root.style.setProperty('--body-txt-color-2', isLightMode ? '#222222' : '#c0baba');
		root.style.setProperty('--rgba-white-1a', isLightMode ? '#0000001a' : '#ffffff1a');
      }, [isLightMode]);


	  useEffect(() => {
		const firstInitializeAndShowBanner = async () => {
			await initializeAdMob();
			await showBanner();
		};
	
		firstInitializeAndShowBanner();
	}, []);

	return (	
		<Auth.Provider value={{isAuthenticated, setIsAuthenticated}} >
			<LightMode.Provider value={{isLightMode, setIsLightMode}} >
				<PushNotificationsComponent />
				<BrowserRouter>
					<ScrollToTop />
					<Routes>
						<Route path="/" element={<HomeTwo />} />
						<Route path="members-classic-mode" element={<Members />} />
						<Route path="members-in-a-map" element={<MembersInMaps />} />
						<Route path="login" element={<LogIn />} />
						<Route path="signup" element={<SignUp />} />
						<Route path="forgetpassword" element={<ForgetPassword />} />
						<Route path="policy" element={<Policy />} />
						<Route path="*" element={<ErrorPage />} />
						<Route path="contact" element={<ContactPage />} />
						<Route path="security" element={<SecurityPage />} />
						<Route path="deleted-profile" element={<DeletedProfile />} />
						<Route path="profile/:username" element={<ProfileUser />} />
						<Route path="reset-password/reset/:token" element={<ResetPassword />} />
						<Route path="activities/:tokenActivity" element={<ShowActivity />} />
						<Route element={<PrivateRoutes />} >
							{/*<Route path="search-all-members" element={<SearchAllMembers />} />*/}
							<Route path="reels/:themeId" element={<ThemeReels />} />
							<Route path="validate-profile" element={<ValidateProfile />} />
							<Route path="news" element={<NewsActivity />} />
							<Route path="profile" element={<ProfileCurrentUser />} />
							<Route path="conversations" element={<Conversations />} />
							<Route path="conversations/chat-requests" element={<ConversationChatRequest />} />
							<Route path="conversations/:participantUsername" element={<ShowConveration />} />
						</Route>
					</Routes>
				</BrowserRouter>
			</LightMode.Provider>
		</Auth.Provider>
	);
}

export default App;
