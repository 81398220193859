import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './assets/css/animate.css';
import './assets/css/icofont.min.css';
import './assets/css/style.css';

import App from './App';
import reportWebVitals from './reportWebVitals';

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import { getAppLanguages } from './service/Language';
import { Capacitor } from '@capacitor/core';
import { getDeviceInfo } from './service/Device';
import { getItem } from './service/LocalStorage';

const isNativePlatform = Capacitor.isNativePlatform();
const appLanguages = getAppLanguages();
const i18NOrder = ['localStorage', 'navigator', 'cookie', 'sessionStorage', 'htmlTag'];
const i18Caches = ['localStorage']; 



if(isNativePlatform) {
  const storageLang = getItem('i18nextLng');
  i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: appLanguages,
    fallbackLng: 'fr',
    detection: {
      order: i18NOrder,
      caches: i18Caches
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    }
  });  
  let deviceInfo = getDeviceInfo();
  deviceInfo.then((value) => {    
    if(!storageLang && appLanguages.includes(value.languageCode)) {
      i18n.changeLanguage(value.languageCode);
    }
  });  
} else {
  const storageLang = getItem('i18nextLng');
  const urlParams = new URLSearchParams(window.location.search);
  const langParam = urlParams.get('lang');

  let i18FallbackLng = 'fr';

  if(storageLang && appLanguages.includes(storageLang)) {
    i18FallbackLng = storageLang;
  } else {
    if(langParam && appLanguages.includes(langParam)) {
      i18FallbackLng = langParam; 
    } else {
      var userNavigatorLang = navigator.language || navigator.userLanguage; 
  
      if(appLanguages.includes(userNavigatorLang)) {
        i18FallbackLng = userNavigatorLang;
      } else {
        var sliceVallang = userNavigatorLang.slice(0,2);
        if(appLanguages.includes(sliceVallang)) {
          i18FallbackLng = sliceVallang;
        }
      }
    }
  }


  i18n
  .use(initReactI18next) 
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: appLanguages,
    fallbackLng: i18FallbackLng,
    detection: {
      order: i18NOrder,
      caches: i18Caches
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json',
    }
  });

  i18n.changeLanguage(i18FallbackLng);

}
  
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
